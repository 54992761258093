import { FlashBladeConfigState } from '../../services/flash-blade-allowable-user-action-service';

export interface FlashBladeCapacityConfigCompParams {
    chassis: FlashBladeChassis[];
    numberOfDrivesPerBlade: number;
    driveRawTB: number;
    increasedUsableTiB: number;

    // these fields are added later in SPoG API, keeping these optional for backward compatibility
    nominalHeat?: number;
    nominalPower?: number;

    // peak heat and power is not consume anywhere yet.
    peakHeat?: number;
    peakPower?: number;

    model?: string;
}

interface FlashBladeChassis {
    numberOfBlades: number;
}

export enum SupportedFlashBladeProductLine {
    S = 'S',
    E = 'E',
}

/**
    keys: actions under question
    values:
        null: if the action is not allowable i.e. will take to a configuration that is not found in perfCalc's response.
        FlashBladeConfigState: i.e. not null which tells the action is allowable, also contains configuration's state info according to perfCalc.
    exception:
        minNumOfBlades - the minimum number of blades for this configuration
        currentConfigState - needed to show this info (raw, usable, heat, power) in Capacity-table
 */
export interface FlashBladeCapacityConfigAllowableActions {
    addHalfChassis: FlashBladeConfigState | null;
    removeHalfChassis: FlashBladeConfigState | null;
    removeBlade: FlashBladeConfigState | null;
    addBlade: FlashBladeConfigState | null;
    addChassis: FlashBladeConfigState | null;
    removeChassis: FlashBladeConfigState | null;
    setDriveCount1: FlashBladeConfigState | null;
    setDriveCount2: FlashBladeConfigState | null;
    setDriveCount3: FlashBladeConfigState | null;
    setDriveCount4: FlashBladeConfigState | null;
    setDriveSize(
        driveSize: SupportedFlashBladeDrives,
        productLine: SupportedFlashBladeProductLine,
    ): FlashBladeConfigState | null;

    // not actions, config info needed from allowableOptions to show in parent components
    minNumOfBlades: number;
    currentConfigState: FlashBladeConfigState;
}

export const FLASH_BLADE_S_DRIVE_SIZES = [24, 37.5, 48.2, 75] as const;
export const FLASH_BLADE_S_DEFAULT_USABLE_TIB = 81.8;
export const FLASH_BLADE_S100_DEFAULT_USABLE_TIB = 127.7;

export const FLASH_BLADE_E_DRIVE_SIZES = [48.2, 75, 150] as const;

export type SupportedFlashBladeDrives =
    | (typeof FLASH_BLADE_S_DRIVE_SIZES)[number]
    | (typeof FLASH_BLADE_E_DRIVE_SIZES)[number];

export const FLASH_BLADE_E_HALF_CHASSIS_DRIVE_SIZES = [75, 150];
export const FLASH_BLADE_E_DEFAULT_USABLE_TIB = 2694.12;

export const FLASH_BLADE_SLOTS_PER_CHASSIS = 10;

export const CONTROLLER_CHASSIS_INDEX_48TB: number[] = [0, 4, 8];
export const CONTROLLER_CHASSIS_INDEX_75TB: number[] = [0, 3, 6, 8];
export const CONTROLLER_CHASSIS_INDEX_150TB: number[] = [0, 2, 4, 6, 8];
