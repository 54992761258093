import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../services/storage.service';
import { Angulartics2 } from 'angulartics2';
import moment from 'moment';
import { OPEN_IN_PLANNER_KEY, OpenInPlannerInfo } from '@pure1/data';

@Component({
    selector: 'capacity-down-licensing-icon',
    templateUrl: './capacity-down-licensing-icon.component.html',
})
export class CapacityDownLicensingIconComponent {
    @Input() readonly applianceId: string;
    @Input() readonly redirectEnabled: boolean = true;

    constructor(
        private readonly router: Router,
        private storageService: StorageService,
        private angulartics2: Angulartics2,
    ) {}

    upgradeClickHandler(): void {
        if (!this.redirectEnabled) {
            return;
        }

        const info: OpenInPlannerInfo = {
            applianceId: this.applianceId,
            timestamp: moment.now(),
            recommendedUsable: 0,
            originalUsable: 0,
        };
        this.storageService.set(OPEN_IN_PLANNER_KEY, JSON.stringify(info));
        this.router.navigate(['/analysis/planning']);

        this.angulartics2.eventTrack.next({
            action: 'Capacity-Down-Licensing-Icon - continue_to_planning',
            properties: { category: 'Action' },
        });
    }
}
