import {
    CONTROLLER_CHASSIS_INDEX_150TB,
    CONTROLLER_CHASSIS_INDEX_48TB,
    CONTROLLER_CHASSIS_INDEX_75TB,
    FLASH_BLADE_E_DRIVE_SIZES,
    FLASH_BLADE_S_DRIVE_SIZES,
    FlashBladeCapacityConfigCompParams,
} from '../../forecast/hardware-capacity-flash-blade/hardware-capacity-flash-blade-common/hardware-capacity-flash-blade-models';
import { isFBE } from '../../utils/marketing';
import { UpgradeFlashBladeHardwareCapacityActionDetails } from '../../services/simulation-step.service';

export class FlashBladeCapacityConfig {
    chassis: FlashBladeChassisConfiguration[];

    constructor(json: any) {
        this.chassis = json.chassis?.map(chassis => new FlashBladeChassisConfiguration(chassis)) || [];
    }
}

export function updateFlashBladeModel(model: string) {
    if (model) {
        return model.replace('FlashBlade//', 'FB-');
    }
    return null;
}

export class FlashBladeHardwareConfig extends FlashBladeCapacityConfig {
    arrayId: string;

    constructor(json: any) {
        super(json);
        this.arrayId = json.array_id;
    }

    getRequestBody(): object {
        return {
            array_id: this.arrayId,
            chassis: this.chassis.map(c => c.getRequestBody()),
        };
    }
}

export class FlashBladeChassisConfiguration {
    blades: Blade[];
    simulated: boolean;

    constructor(json: any) {
        this.blades = json.blades?.map(b => new Blade(b)) ?? [];
        this.simulated = json.simulated ?? true; // default to true if it wasn't provided(net-new //S and net-new //E)
    }

    getRequestBody(): object {
        return {
            blades: this.blades.map(b => b.getRequestBody()),
            simulated: this.simulated,
        };
    }
}

export class Blade {
    controller: string;
    drives: Drive[];
    rawTB: number;
    simulated: boolean;

    constructor(json: any) {
        this.controller = json.controller;
        this.drives = json.drives?.map(d => new Drive(d)) ?? [];
        this.rawTB = json.raw_TB;
        this.simulated = json.simulated ?? true; // default to true if it wasn't provided(net-new //S and net-new //E)
    }

    getRequestBody(): object {
        return {
            controller: this.controller,
            drives: this.drives.map(d => d.getRequestBody()),
            raw_TB: this.rawTB,
            simulated: this.simulated,
        };
    }
}

export class Drive {
    id: string;
    raw_TB: number;
    original_raw_TB?: number;
    simulated: boolean;

    constructor(json: any) {
        this.id = json.id;
        this.raw_TB = json.raw_TB;
        this.original_raw_TB = json.original_raw_TB;
        this.simulated = json.simulated ?? true; // default to true if it wasn't provided(net-new //S and net-new //E)
    }

    getRequestBody(): object {
        return {
            id: this.id,
            raw_TB: this.raw_TB,
            original_raw_TB: this.original_raw_TB,
            simulated: this.simulated,
        };
    }
}

export function getTotalBladeCount(fbCapacityConfig: FlashBladeCapacityConfig): number {
    let totalBladeCount = 0;
    fbCapacityConfig.chassis.forEach(chassis => (totalBladeCount += chassis.blades.length));
    return totalBladeCount;
}

export function getTotalRawTB(fbCapacityConfig: FlashBladeCapacityConfig): number {
    let totalRawTB = 0;
    fbCapacityConfig.chassis.forEach(chassis => {
        for (const blade of chassis.blades) {
            for (const drive of blade.drives) {
                totalRawTB += drive.raw_TB;
            }
        }
    });

    return parseFloat(totalRawTB.toFixed(4));
}

export function convertToFlashBladeCapacityConfigCompParams(
    upgradeFlashBladeHardwareCapacityActionDetails: UpgradeFlashBladeHardwareCapacityActionDetails,
    model?: string,
) {
    return {
        chassis: upgradeFlashBladeHardwareCapacityActionDetails?.chassis?.map(c => {
            return { numberOfBlades: c?.blades?.length ?? 0 };
        }),
        numberOfDrivesPerBlade:
            upgradeFlashBladeHardwareCapacityActionDetails?.chassis[0]?.blades[0]?.drives?.length ?? 0,
        driveRawTB:
            upgradeFlashBladeHardwareCapacityActionDetails?.chassis[0]?.blades[0]?.drives[0]?.raw_TB ??
            FLASH_BLADE_S_DRIVE_SIZES[0],
        increasedUsableTiB: upgradeFlashBladeHardwareCapacityActionDetails?.increased_usable_TiB ?? 0,
        model: model,
    };
}

export function convertFromFlashBladeCapacityConfigCompParams(
    compParams: FlashBladeCapacityConfigCompParams,
    model: string,
): FlashBladeCapacityConfig {
    if (isFBE(model)) {
        let currentModel: string;
        const chassisList = [];
        for (let i = 0; i < compParams.chassis.length; i++) {
            if (isControllerChassis(i, compParams.driveRawTB)) {
                currentModel = 'FB-EC';
            } else {
                currentModel = 'FB-EX';
            }
            const blades = [];
            for (let j = 0; j < compParams.chassis[i].numberOfBlades; j++) {
                const drives = [];
                for (let k = 0; k < compParams.numberOfDrivesPerBlade; k++) {
                    drives.push({
                        raw_TB: compParams.driveRawTB,
                    });
                }
                blades.push({ controller: currentModel, drives: drives });
            }

            chassisList.push({ blades: blades });
        }

        return new FlashBladeCapacityConfig({ chassis: chassisList });
    } else {
        // isFBS
        return new FlashBladeCapacityConfig({
            chassis: compParams.chassis.map(chassis => {
                const blades = [];
                for (let i = 0; i < chassis.numberOfBlades; i++) {
                    let bladeRawTB = 0;
                    const drives = [];
                    for (let j = 0; j < compParams.numberOfDrivesPerBlade; j++) {
                        drives.push({
                            raw_TB: compParams.driveRawTB,
                        });
                        bladeRawTB += compParams.driveRawTB;
                    }
                    blades.push({
                        drives: drives,
                        rawTB: bladeRawTB,
                        controller: updateFlashBladeModel(model),
                    });
                }

                return { blades: blades };
            }),
        });
    }
}

export function isControllerChassis(chassisIndex: number, driveRawTB: number): boolean {
    // FB-E configurations with 75TB DFMe will have 1st, 4th, 7th and 9th chassis as control chassis.
    if (driveRawTB === FLASH_BLADE_E_DRIVE_SIZES[1]) {
        return CONTROLLER_CHASSIS_INDEX_75TB.includes(chassisIndex);
    } else if (driveRawTB === FLASH_BLADE_E_DRIVE_SIZES[2]) {
        // FB-E configurations with 150TB DFMe will have 1st, 3rd, 5th, 7th and 9th chassis as control chassis.
        return CONTROLLER_CHASSIS_INDEX_150TB.includes(chassisIndex);
    }
    // FB-E configurations with 48TB DFMe will have 1st, 5th, and 9th chassis as control chassis.
    return CONTROLLER_CHASSIS_INDEX_48TB.includes(chassisIndex);
}
