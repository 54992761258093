import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { CachedCurrentUserService, FeatureFlagDxpService } from '@pure1/data';
import { init } from '@commandbar/foobar';
import { firstValueFrom } from 'rxjs';
import { WINDOW } from './injection-tokens';
import { OrgSwitcherService, PlatformClientService } from '@pure/pure1-ui-platform-angular';
import { FeatureNames } from '../model/FeatureNames';
import { isPure1Production } from '../utils/url';
import { ProductConfig } from '@commandbar/foobar/build/types/internal/src/client/CommandBarClientSDK';

@Injectable({ providedIn: 'root' })
export class CommandAiConfigService implements OnDestroy {
    constructor(
        @Inject(WINDOW) private window: Window,
        private cachedCurrentUserService: CachedCurrentUserService,
        private orgSwitcherService: OrgSwitcherService,
        private featureFlagDxpService: FeatureFlagDxpService,
        private platformClientService: PlatformClientService,
        private ngZone: NgZone,
    ) {}

    async run(): Promise<void> {
        // if we don't await, platform won't be initialized and getting userOrgs will fail
        await (this.window as any).dxp.ready;

        // if user is not logged in, don't initialize command bar because some pages are not hidden behind login and the followup call will fail
        if (!(await firstValueFrom(this.platformClientService.getIsLoggedIn()))) {
            return;
        }

        const isOrgSwitchingEnabled = await firstValueFrom(this.orgSwitcherService.enabled$);

        const [userOrgs, activeOrg, currentUser, logCenterFF] = await Promise.all([
            isOrgSwitchingEnabled ? firstValueFrom(this.orgSwitcherService.userOrgs$) : [],
            isOrgSwitchingEnabled ? firstValueFrom(this.orgSwitcherService.activeOrg$) : null,
            firstValueFrom(this.cachedCurrentUserService.get()),
            firstValueFrom(this.featureFlagDxpService.getFeatureFlag(FeatureNames.LOG_CENTER)),
        ]);
        const isAdmin = currentUser.role.toLowerCase() === 'pure1_admin';
        const isSupport = currentUser.role.toLowerCase() === 'pure1_support';

        void this.ngZone.runOutsideAngular(async () => {
            await init('bbc400ad');

            const enabledProducts: ProductConfig = ['nudges'];

            if (!isPure1Production(this.window.location)) {
                enabledProducts.push('spotlight', 'checklists', 'help_hub');
            }

            this.window.CommandBar.addMetadata('hasOrgSwitcher', userOrgs.length > 1);
            this.window.CommandBar.addMetadata('hasLogCenter', !!logCenterFF.enabled);
            this.window.CommandBar.addMetadata('isAdmin', isAdmin);
            this.window.CommandBar.addMetadata('isSupport', isSupport);
            if (isOrgSwitchingEnabled) {
                this.window.CommandBar.addMetadata('activeOrgCategory', activeOrg.category);
            }
            this.window.CommandBar.addMetadata('app', 'pure1');

            await this.window.CommandBar.boot(currentUser.originalUserEmail, undefined, {
                products: enabledProducts,
            });
        });
    }

    ngOnDestroy(): void {
        this.ngZone.runOutsideAngular(() => {
            this.window.CommandBar.shutdown();
        });
    }
}
